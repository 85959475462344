import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ActionBar,
  CustomB,
  InventoryTableStyled,
  PageContainer,
} from "./ConsignerInventory";
import InventoryFilter from "../components/Common/InventoryFilter";
import {
  getInventoryFilterOptions,
  getInventories,
  cancelDropoff,
  calculateFeesFromAPI,
  acceptRequestToSell,
} from "../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { UserState } from "../redux/reducers/UserReducer";
import { Button, Table, Image, Popconfirm, Spin } from "antd";
import "./ConsignerInventory.css";
import RedesignStyling from "../constants/RedesignStyling";
import { ReviewListingView } from "./ConsignerProducts";
import { AppState } from "../redux/reducers/AppReducer";
import { StoreState } from "../redux/reducers/StoreReducer";
import Colors from "../constants/Colors";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import ButtonGroup from "antd/lib/button/button-group";

const ConsignerPriceChangeRequests = (props: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    inventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const [dataSource, setDataSource] = useState<any[]>([]);

  const [filters, setFilters] = useState<any>({
    printed: "",
    status: "StoreRequestPriceChange",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: "",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
  });

  const computePrices = async () => {
    const dt = await Promise.all(
      inventories.map(async (record) => {
        const payoutAmount = (
          await calculateFeesFromAPI(
            record.product.id,
            Number(record.requestedPrice),
            1,
            record.option1Value,
            record.option2Value,
            record.option3Value,
            Number(record.cost),
            record.category,
            JSON.stringify(dbUser)
          )
        ).payout;
        return {
          ...record,
          currency: store.currency,
          payoutAmount: payoutAmount.toFixed(2),
          subtotalPayout: (payoutAmount - record.payoutFee).toFixed(2),
        };
      })
    );
    setDataSource(dt);
  };

  const acceptPriceChange = async (record: Inventory) => {
    await dispatch(
      acceptRequestToSell({
        ...record,
        price: record.requestedPrice,
        requestedPrice: null,
      })
    );
    await dispatch(getInventories("", filters));
  };

  useEffect(() => {
    computePrices();
  }, [inventories]);

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  const handleCancel = async (id: number[]) => {
    await dispatch(cancelDropoff(id, dbUser.id));
    await dispatch(getInventories("", filters));
    setShowSuccessModal(true);
  };

  const handleSelect = (checked: boolean, rowKey: React.Key) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, rowKey]);
    } else {
      setSelectedRowKeys([...selectedRowKeys.filter((key) => key !== rowKey)]);
    }
  };

  const visibleColumns: ColumnTypes[number][] = [
    {
      title: "Product",
      key: "productImage",
      dataIndex: "product.image",
      render: (_: any, record: any) => (
        <Image
          src={record.product.image}
          alt=""
          style={{ width: 100, height: "auto" }}
        ></Image>
      ),
    },
    {
      title: "Name",
      key: "productName",
      dataIndex: "product.title",
      render: (_: any, record: any) => (
        <span>
          {record.product.title}
          <br />
          {record.code},
        </span>
      ),
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "product.sku",
      render: (_: any, record: any) => (
        <span style={{ fontSize: 11 }}>{record.product.sku}</span>
      ),
    },
    {
      title: "Location",
      key: "option3Value",
      dataIndex: "option3Value",
      render: (_: any, record: any) => record?.option3Value ?? null,
    },
    {
      title: "Size",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value ?? null,
    },
    {
      title: "Condition",
      key: "option2Value",
      dataIndex: "option2Value",
      render: (_: any, record: any) => record?.option2Value ?? null,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_: any, record: any) => (
        <CustomB className="red">Store Request Price Change</CustomB>
      ),
    },
    {
      title: "Date",
      key: "acceptedOn",
      dataIndex: "acceptedOn",
      render: (_: any, record: any) =>
        moment(record.acceptedOn).format("YY/MM/DD"),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency)}${record.price}`,
    },
    {
      title: "Requested Price",
      key: "requestedPrice",
      dataIndex: "requestedPrice",
      render: (_: any, record: any) => (
        <CustomB className="red">
          {getSymbolFromCurrency(record.currency)}
          {record.requestedPrice}
        </CustomB>
      ),
    },
    {
      title: "New Payout",
      key: "payoutAmount",
      dataIndex: "payoutAmount",
      render: (_: any, record: any) => (
        <CustomB className="red">
          {getSymbolFromCurrency(record.currency)}
          {record?.payoutAmount}
        </CustomB>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_: any, record: any) => (
        <ButtonGroup>
          <Popconfirm
            title="Do you want to accept this price change request?"
            onConfirm={() => acceptPriceChange(record)}
            okText="Confirm"
            okButtonProps={{ type: "primary" }}
            cancelButtonProps={{ type: "text" }}
            icon={false}
          >
            <Button type="primary">Accept</Button>
          </Popconfirm>
          <Popconfirm
            title="Do you want to cancel this Dropoff?"
            onConfirm={() => handleCancel([record.id])}
            okText="Confirm"
            okButtonProps={{ type: "primary" }}
            cancelButtonProps={{ type: "text" }}
            icon={false}
          >
            <Button danger type="primary">
              Cancel
            </Button>
          </Popconfirm>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //refresh inventory list on filter or search change
    dispatch(getInventories("", filters));
  }, [filters]);

  return (
    <PageContainer className="ConsignerPriceChangeRequests">
      <ActionBar>
        <InventoryFilter
          onFilter={(values) => setFilters(values)}
          formOptions={inventoryFilterOptions}
          showConsignerFilter={false}
          showStatusFilter={false}
          subLocations={[]}
          currentFilters={filters}
          option1Values={inventoryFilterOptions.option1Values}
          option2Values={inventoryFilterOptions.option2Values}
          option3Values={inventoryFilterOptions.option3Values}
        />
      </ActionBar>
      {isMobile ? (
        inventoriesLoading ? (
          <Spin />
        ) : (
          <ReviewListingView
            inventoryList={dataSource}
            isInventory={true}
            handleDelete={handleCancel}
            store={store}
            isDropOff={false}
            handleAcceptInventory={acceptPriceChange}
            selectedRowKeys={selectedRowKeys}
            isPriceChange={true}
          />
        )
      ) : (
        <InventoryTableStyled
          className="InventoryTable"
          dataSource={dataSource}
          columns={visibleColumns}
          size="middle"
          pagination={{
            pageSize,
            // responsive: true,
            hideOnSinglePage: true,
            showSizeChanger: true,
            // onShowSizeChange: handlePageSizeChange,
          }}
          loading={inventoriesLoading}
          rowKey="id"
        />
      )}
    </PageContainer>
  );
};

export default ConsignerPriceChangeRequests;
